.miniChecklist {
	background: #c76400;
	border: 0;
	outline: 0;
	padding: 16px;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	bottom: 8px;
	width: inherit;
	text-align: left;
	cursor: pointer;
}

.miniChecklistTitle {
	font-weight: bold;
	font-size: 14px;
}

.openButton {
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;

	svg {
		width: 11px;
	}
}

.message {
	margin-top: 14px;
	font-size: 12px;
	text-align: center;
}

.taskList {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 2px;
	margin-top: 10px;

	li > * {
		display: block;
		width: 100%;
	}

	li:focus-within {
		z-index: 10;
	}
}

.taskItem {
	background: #fee0a8;
	box-shadow: inset 0px -1px 0px #ffb731;
	transform-origin: center;
	transition: transform 100ms ease;
	height: 9px;
	width: 100%;

	&.taskItemComplete {
		background: white;
	}

	&:hover,
	&:focus {
		transform: scaleY(1.4);
	}
}

.progressBar {
	position: relative;
	padding-right: 18px;
	margin-top: 12px;

	ol,
	ul {
		list-style: none;
	}
}

.starIcon {
	width: 18px;
	height: 18px;
	position: absolute;
	background: white;
	border-radius: 9999px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 0 4px #fee0a8;
	top: 50%;
	right: 4px;
	color: var(--lns-color-yellowDark);
	transform: translateY(-50%);

	svg {
		width: 12px;
		height: 12px;
	}
}
.flex {
	display: flex;
}
.justify-between {
	justify-content: space-between;
}
.items-center {
	align-items: center;
}
