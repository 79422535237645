.openChecklistContainer {
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 0px;
	bottom: 8px;
	width: inherit;
	z-index: 1;
	background: white;
	border-radius: 6px;
}

.openChecklistHeader {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	background: #c76400;
	padding: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.openChecklistTasks {
	padding: 8px 0 16px;
	background: white;
}

.openChecklistCloseButton {
	border: 0;
	outline: 0;
	background: none;
	height: 32px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	cursor: pointer;
}
