.details {
  padding: 8px 16px;
  
  &.completionAnimation {
    animation-name: flash-background;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-duration: 600ms;
  }
}

@keyframes flash-background {
  0%, 100% {
    background: hsla(206,100%, 73%, 0);
  }
  50% {
    background: hsla(206,100%, 73%, 0.2);
  }
}

.summary {
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: scale(1);
  color: #6C6C7F;

  &::-webkit-details-marker {
    display: none;
  }

  .disabled & {
    cursor: default;
  }

  .isCurrentTask & {
    color: black;
  }  
}

.statusIcon {
  width: 24px;
  height: 24px;
  border: 1px solid #dcdce0;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  .isCurrentTask & {
    background-color: #fff7e8;
    border-color: #C76400 ;
    position: relative;
    opacity: 1;
  }

  .isCurrentTask &::before {
    --offset: 1px;
    content: '';
    position: absolute;
    border-radius: 9999px;
    background: #fff7e8;
    background: #C76400 ;
    height: calc(100% + (var(--offset) * 2));
    width: calc(100% + (var(--offset) * 2));
    top: calc(var(--offset) * -1);
    left: calc(var(--offset) * -1);
    z-index: -1;
    transform-origin: center;
    will-change: transform;
    animation-name: scale-up;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.44, -0.01, 0.27, 0.92);
    animation-duration: 1.5s;

    @media (prefers-reduced-motion) {
      animation-duration: 3s;
    }
  }

  .isComplete & {
    background-color: #FFE9C1;
    border-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.88574 3.8288L4.6286 6.57165L10.1143 1.08594" stroke="%23C76400 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.title {
  font-size: 14px;
  color: #6C6C7F;

  .isCurrentTask & {
    font-weight: 600;
  }

  .isComplete & {
    text-decoration: line-through;
  }
}

.content {
  padding-left: 36px;
  margin-top: 2px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEF;
}

.description {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}

@keyframes scale-up {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.toggleIcon {
  color: currentColor;
  width: 20px;
  margin-left: auto;
  transition: transform 200ms linear;

  [open] > summary & {
    transform: rotate(180deg);
  }
}