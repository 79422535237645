.st0 {
	fill: #bdc3c7;
}
.st1 {
	font-family: "NexaBold";
}
.st2 {
	font-size: 9.2189px;
}
.st3 {
	fill: #24ae5f;
}
.st4 {
	fill: #726658;
}
.st5 {
	fill: none;
	stroke: #95a5a5;
	stroke-miterlimit: 10;
}

.daytime_wrapper {
	width: 100%;
	height: 2px;
	position: relative;
	background: #bdc3c7;
	margin: 20px 0;
}
.active_hours {
	position: absolute;
	background: #24ae5f;
	height: 100%;
	z-index: 2;
}
.break_hours {
	position: absolute;
	background: #c0392b;
	height: 100%;
	z-index: 3;
}
