.openChecklistEnter {
  --slide-offset: 20px;
  opacity: 0;
  animation-name: slide-in;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.openChecklistExit {
  --slide-offset: 20px;
  animation-name: slide-in;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-direction: reverse;
}

.miniChecklistEnter {
  --slide-offset: -8px;
  opacity: 0;
  animation-name: slide-in;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}


@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(var(--slide-offset));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
