.collapsedWrapper {
	position: absolute;
	left: 0;
	bottom: 0;
}
.collapsedButton {
	--yellow: var(--lns-color-yellowDark);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	width: 64px;
	background: var(--yellow);
	color: var(--yellow);

	&:hover {
		--yellow: #ffaf1a;
	}
}

.collapsedButtonStar {
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	position: relative;
	width: 24px;
	border-radius: 9999px;
	box-shadow: 0 0 0 4px #fee0a8;

	svg {
		margin-top: -5%;
		margin-left: -0.5%;
		width: 20px;
	}
}

.collapsedButtonBadge {
	position: absolute;
	color: white;
	background: var(--lns-color-blurpleDark);
	border-radius: 9999px;
	width: 16px;
	display: block;
	font-size: 12px;
	top: 100%;
	left: 100%;
	height: 16px;
	line-height: 16px;
	font-variant-numeric: tabular-nums;
	box-shadow: 0 0 0 2px var(--yellow);

	transform: translate(-40%, -40%);
}

.reminder {
	position: absolute;
	left: calc(100% + 16px);
	bottom: calc(8px);
	padding: 30px 24px;

	background: #c76400;
	width: 260px;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
}

.reminderIcon {
	width: 24px;
}

.reminderDismiss {
	font-family: Arial;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	padding: 4px;
	margin-left: -4px;
	margin-top: -4px;
}
