@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
:root {
    --light-gray1:#F5F5F5;
    --light-gray2:#757688;
    --light-gray3:#B3B3B3;
    --dark-gray1: #1f1f1f;
    --dark-gray2: #282828;
    --dark-gray3:#404040;
    --dark-gray4:#575757;
    --orange: #C76400;
    --glow:#A9FF53;
    --green:#15AE73;
    --white: #ffffff;
    --black: #000000;
}

body, html{
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        background: #282828;
        
}
.recharts-text{
    stroke: none;
    
    text-anchor: end;
}
.recharts-text tspan{
    color: yellow !important;
    background: #ccc !important;
    padding:10px !important;
    stroke: none;
    fill: rgb(255,255,255) !important;
    text-anchor: end;
}



::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--dark-gray1);
    border: solid 1px var(--dark-gray3); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--dark-gray2) 
  }

.icon.options {
    width: 50px;
    height: 50px;
    background-image: url('./images/options.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
}

a {
    text-decoration: none !important;
    color: unset;
}

.capitalize {
    text-transform: capitalize;
}

.justify_left {
    justify-content: flex-start !important;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none !important;
    color: unset;
}

.App,
.body {
    display: flex;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.back {
    display: flex;
    cursor: pointer;
}

.back_icon {
    width: 8px;
    height: 24px;
    cursor: pointer;
    background: url("./icons/back.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 6px;
}

.back_text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: #66687E;
}

::-webkit-input-placeholder {
    /* Edge */
    color: #757688;
}

:-ms-input-placeholder {
    /* DM Sansnet Explorer 10-11 */
    color: #757688;
}

::placeholder {
    color: #757688;
}

.sidebar_ {
    width: 284px;
    height: 100vh;
    background: var(--dark-gray2);
    padding: 31px 40px;
    font-family: 'DM Sans', sans-serif;
}

.sidebar_ .logo {
    height: 42px;
    width: 42px;
    /* background: url("./icons/kfc.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
}

.sidebar_ .logo img {
    max-width: -webkit-fill-available;
}

.sidebar_ .main-title {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #353535;
  margin-bottom: 16px;
}

.sidebar_ .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: var(--light-gray3);
    margin: 12px 0;
}

.sidebar_ .side-nav-item {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: var(--light-gray3);
    margin-top: 15px;
    /* background: var(--dark-gray1); */
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
}

.sidebar_ .disabled {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #353535;
  margin-top: 25px;
  cursor: not-allowed;
  display: flex;
}
.sidebar_ .side-nav-item.disabled:hover {
  color: #353535 !important;
}

.sidebar_ .side-nav-item.side-nav-item-parent {
    position: relative;
}

.sidebar_ .side-nav-item .right-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 5px;
    right: 0;
    transition-duration: 0.2s;
}

.sidebar_ .side-nav-item .right-icon.up {
    background: url('./icons/upArrow.svg');
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.sidebar_ .side-nav-item .right-icon.down {
    background: url('./icons/downArrow.svg');
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}


.side-nav-item-child {
    padding-left: 15px !important;
}

.sidebar_ .side-nav-item.active{
    /* background: var(--dark-gray3); */
    background: var(--dark-gray1);
    border-radius: 25px;
}
.sidebar_ .side-nav-item:active,
.sidebar_ .side-nav-item:hover,
.sidebar_ .side-nav-item.active .text {
    /* color: var(--glow) !important; */
    color: var(--orange) !important
}

.sidebar_ .side-nav-item .icon {
    width: 14px;
    height: 14px;
    margin-right: 9.33px;
    margin-top: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sidebar_ .side-nav-item .icon.dashboard {
    background: url("./icons/dashboard.svg");
}

.sidebar_ .side-nav-item .icon.revenue {
    background: url("./icons/revenue.svg");
}

.sidebar_ .side-nav-item .icon.rewards {
    background: url("./icons/rewards.svg");
}

.sidebar_ .side-nav-item .icon.products {
    background: url("./icons/products.svg");
}


.sidebar_ .side-nav-item .icon.growth-accounting {
  background: url("./icons/products.svg");
}

.sidebar_ .side-nav-item .icon.customers {
    background: url("./icons/customers.svg");
}

.sidebar_ .side-nav-item .icon.stores {
    background: url("./icons/stores.svg");
}

.sidebar_ .side-nav-item .icon.account {
    background: url("./icons/account.svg");
}

.sidebar_ .side-nav-item:hover .icon.dashboard,
.sidebar_ .side-nav-item.active .icon.dashboard {
    background: url("./icons/dashboard_hover.svg");
}

.sidebar_ .side-nav-item:hover .icon.rewards,
.sidebar_ .side-nav-item.active .icon.rewards {
    /* background: url("./icons/rewards_hover.svg"); */
    background: url("./icons/rewards.svg");
}

.sidebar_ .side-nav-item:hover .icon.revenue,
.sidebar_ .side-nav-item.active .icon.revenue {
    background: url("./icons/revenue_hover.svg");
}

.sidebar_ .side-nav-item:hover .icon.products,
.sidebar_ .side-nav-item.active .icon.products {
    background: url("./icons/products_hover.svg");
}

.sidebar_ .side-nav-item:hover .icon.customers,
.sidebar_ .side-nav-item.active .icon.icon.customers {
    background: url("./icons/customers_hover.svg");
}

.sidebar_ .side-nav-item:hover .icon.stores,
.sidebar_ .side-nav-item.active .icon.stores {
  /* background: url("./icons/stores_hover.svg"); */
  background: url("./icons/stores.svg");
}

.sidebar_ .side-nav-item:hover .icon.account,
.sidebar_ .side-nav-item.active .icon.account {
    background: url("./icons/account_hover.svg");
}

.mainbar {
    width: calc(100% - 284px);
    height: 100vh;
    background: var(--dark-gray1);
    overflow-y: scroll;
    overflow-x: hidden;
}

.cashier-main {
    height: 100%;
    background: #fff;
    /* overflow-y: hidden; */
    overflow-x: hidden;
}

.mainbar-container {
    width: 90%;
    margin: 0.813rem 5.063rem;
    margin: auto;
    position: relative;
    padding-top: 0.813rem;
}

.full-mainbar-container {
    margin: 0.813rem 5.063rem;
    position: relative;
    /* padding-top: 13px; */
}

.half-container {
    height: 100%;
}

.full-width {
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: stretch
}


/* top bar */

.topbar {
    /* padding: 22px 20px 12px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: #fff; */
    position: relative;
    min-height: 50px;
    border-bottom: 1px solid var(--dark-gray3);
    /* padding: 1rem; */
    padding: 1rem 5.063rem;
    padding: 1rem 5%;
}

.topbar .page-anchor {
    width: 50px;
    height: 50px;
    background: url("./icons/menu.svg");
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    /* margin: 15px; */
    cursor: pointer;
    display: none;
}

.topbar .right-box {
    /* width: 464px; */
    height: 50px;
    /* background: #353535; */
    /* position: absolute;
    right: 0;
    top:22px; */
    display: flex;
}

.topbar .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* line-height: 84px; */
    color: var(--white);
    /* margin-left: 22px; */
}

.topbar .title.small {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
}

.right-box .download_btn {
    float: left;
}

.btn_ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    min-width: 196px;
    height: 50px;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-transform: uppercase;
    padding: 0px 2rem;
    text-align: center;
    color: var(--dark-gray2);
    line-height: 50px;
    cursor: pointer;
}

.btn_sm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 35px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 171% */
  text-transform: uppercase;
  padding: 0px 2rem;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.top_btn {
    margin-top: 12px;
}

.table_btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height, or 171% */
    font-family: 'DM Sans', sans-serif;
    color: var(--white);
    background: #02965611;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #029656;
}

.btn_orange {
    background: var(--glow);
    border: none;
}

.btn_dumb {
    background: #bdc3c7 !important;
    border: none;
}

.btn_white {
    color: var(--dark-gray1);
    background: #FFFFFF;
    border: none;
}

.btn_clear {
    color: rgba(0, 0, 0);
    border: none;
}

.btn_green {
    background: var(--glow);
}

.btn_inactive {
    background: gainsboro;
}

.profile {
    float: left;
    display: flex;
    margin-left: 22px;
    align-items: center
}

.settings_dropdown {
    position: absolute;
    width: 121px;
    height: 35px;
    top: 56px;
    right: 61px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
}

.list_box {
  width: 100%;
  max-width: 616px;
  height: auto;
  background: #FDFDFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 32px;
}

.note_box {
  width: 100%;
  max-width: 616px;
  height: auto;
  background: #F2F2F7;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 20px;
}

.note_box .title {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: var(--white);
}

.note_box .value {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #353535;
}

.list_item {
    display: flex;
    justify-content: space-between;
}
.list_wrap {
  border-bottom: 1px solid #00000020;
}

.list_wrap:last-child {
    border-bottom: unset
}

.special_request {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  color: #353535;
  padding-bottom: 24px;
}

.list_item .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #353535;
    padding-top: 24px;
    padding-bottom: 24px;
}

.list_item .value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #353535;
    padding-top: 24px;
    padding-bottom: 24px;
}

.list_box_total {
    width: 616px;
    height: 70px;
    left: 365px;
    top: 741px;
    background: #F2F2F7;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
}

.list_box_total .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--white);
}

.list_box_total .value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #353535;
}

.settings_dropdown_list {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    /* identical to box height, or 171% */
    color: #E11010;
    text-align: center;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
}

.settings_dropdown_list:hover {
    background: #FCEAD8;
}

.profile .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #50106E;
}

.profile .profile-details {
    font-family: 'DM Sans', sans-serif;
    margin-left: 15px;
}

.profile .icon {
    width: 25px;
    height: 50px;
    cursor: pointer;
}

.icon.more {
    background: url("./icons/down.svg");
    background-size: 9px;
    background-repeat: no-repeat;
    background-position: center;
}

.profile .profile-details .username {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: var(--light-gray3);
}

.profile .profile-details .email {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: var(--light-gray3);
}

.section-title {
    width: 100%;
    /* background: #8D8DA4; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 66px;
    /* identical to box height, or 171% */
    font-family: 'DM Sans', sans-serif;
    color: var(--white);
}


/* summary box */

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.summary_box {
    width: 50%;
    height: 70px;
    
    background: var(--dark-gray2);
    /* border: 1px solid var(--dark-gray4); */
    box-sizing: border-box;
    /* border-radius: 6px; */
    /* padding: 22px 20px; */
    
    /* margin-right: 20px; */
    /* margin-top: 20px; */
}

.summary_box .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    font-family: 'DM Sans', sans-serif;
    color: #8D8DA4;
}

.summary_box .value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    /* identical to box height, or 92% */
    color: var(--white);
    margin-top: 14px;
}

.summary_box .create-wallet {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #C76400;
    margin-top: 14px;
    cursor: pointer;
}


/*Details box */

.details_box {
    box-sizing: border-box;
    padding: 22px 12px;
    overflow-x: auto;
}

.details_box .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    font-family: 'DM Sans', sans-serif;
    color: #8D8DA4;
}

.details_box .value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 92% */
    color: var(--white);
    margin-top: 14px;
}


/* pie chart */

#active-sector,
.recharts-pie-sector {
    z-index: 11 !important;
}

#tooltip {
    z-index: 1111 !important;
}

.recharts-pie-sector {}

.piechart_wrapper {
    width: 100%;
    height: auto;
    left: 365px;
    top: 326px;
    background: var(--dark-gray2);
    border: 1px solid var(--dark-gray3);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    padding: 24px;
    margin-bottom: 25px;
}

.piechart {
    width: 200px;
    height: 169px;
}

.piechart_details {
    width: calc(100% - 169px);
    height: 169px;
    /* background: #004751; */
    padding: 10px;
}

.piechart_details_item {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
}

.piechart_details_item.active {
    border: 1px solid #E0E0E0;
    background: #FCEAD8;
}

.piechart_details_item .piechart_color_icon {
    width: 12px;
    height: 12px;
    background: #ccc;
    margin: 6px;
}

.piechart_details_item .name {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #757688;
    margin-left: 3px;
}

.piechart_details_item .value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: var(--white)
}


/* filters */

.page-filter {
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 25px;
}

.page-filter .button {
    width: 135px;
    height: 40px;
    background: var(--dark-gray2);
    border: 1px solid var(--dark-gray4);
    box-sizing: border-box;
    border-radius: 30px;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.page-filter .button .text {
    font-size: 14px;
    color: var(--light-gray3)
}

.page-filter .button .icon {
    width: 16px;
    height: 7px;
    /* background: url("./icons/down.svg"); */
    margin-left: 5px;
    background: url("./icons/down.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition-duration: 1s;
}

.page-filter .button .icon.up {
    transform: rotate(180deg);
    transition-duration: 1s;
}

.page-filter-options {
    width: 121px;
    height: auto;
    background: var(--dark-gray3);
    border: 1px solid var(--dark-gray4);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 5px;
    padding: 0 8px;
    max-height: 180px;
    overflow-y: auto;
    transition-duration: 0.5s;
    cursor:pointer;
}

.page-filter-option {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: var(--light-gray3);
    padding: 2px 0;
    cursor: pointer;
    padding: 0 2px;
}

.page-filter-option:hover,
.page-filter-option.active {
    background: var(--dark-gray4);
}

.page-filter-options .page-filter-option:not(:last-child) {
    border-bottom: #00000020 solid 1px;
}

.sidetab-title-wrapper {
    width: 200px;
    /* padding-left: 71px; */
    padding-top: 34px;
}

.tab-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*     padding: 20px 0; */
    margin: 20px 0;
}

.tab-title-wrapper.underline {
    border-bottom: 1px solid var(--glow);
}

.tab-title-wrapper .page-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--dark-gray4);
    font-family: 'DM Sans', sans-serif;
    margin-right: 23px;
    cursor: pointer;
    height: 33px;
}

.tab-title-wrapper .page-title.active {
    border-bottom: 2px solid var(--glow);
    color: var(--light-gray1);
}

.sidetab-title-wrapper .page-title {
    width: 100%;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #979797;
    margin-bottom: 18px;
    cursor: pointer;
    display: flex;
    align-items: stretch;
}

.sidetab-title-wrapper .page-title .border-left {
    width: 3px;
    background: none;
    margin-right: 11px;
}

.sidetab-title-wrapper .page-title.active {
    color: #C76400;
}

.sidetab-title-wrapper .page-title.active .border-left {
    border: unset;
    border-left: 3px solid #C76400
}

.bar-chart-wrapper {
    width: 100%;
    height: 400px;
    font-family: 'DM Sans', sans-serif;
    /* border: 1px solid #E0E0E0; */
    box-sizing: border-box;
    /* border-radius: 6px; */
    /* margin-bottom: 25px; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #25233A;
    mix-blend-mode: normal;
    opacity: 0.6;
}

.responsive-chart-wrapper {
  width: 100%;
  /* height: 500px; */
  font-family: 'DM Sans', sans-serif;
  background: #FEFEFF;
  background: #F2F2F7;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #25233A;
  mix-blend-mode: normal;
}

.recharts-legend-item {
  margin-bottom: 4px;
}

.table-filters {
    display: flex;
    justify-content: flex-end;
    /* background: #ccc; */
    /* padding: 7px; */
    align-items: center;
}

.table-status-filter,
.table-date-filter {
    font-size: 14px;
    margin-right: 13px;
    position: relative;
}

.table-date-filter.orange {
    color: #C76400 !important;
}

.table-status-filter .page-filter-options {
    position: absolute;
}

.table-date-filter {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.table-date-filter .icon {
    width: 16px;
    height: 16px;
    background: url("./icons/calendar.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.table-date-filter.orange .icon {
    width: 16px;
    height: 16px;
    background: url("./icons/calendar_orange.svg") !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat !important;
    margin-right: 5px;
}


.table-date-filter .icon.filtertable {
    width: 16px;
    height: 16px;
    background: url("./icons/filtertable.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.table-status-filter .button {
    width: 121px;
    height: 40px;
    background: var(--dark-gray2);
    border: 1px solid var(--dark-gray4);
    color: var(--light-gray3);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.table-status-filter .button .text {
    font-size: 14px;
}

.table-status-filter .button .icon {
    width: 16px;
    height: 7px;
    background-size: contain !Important;
    background-position: center;
    background-repeat: no-repeat !Important;
    margin-left: 5px;
    background: url("./icons/down.svg");
}


/* search box */

.search_wrapper {
    width: 284px;
    height: 40px;
    background: var(--dark-gray2);
    border: 1px solid var(--dark-gray4);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    padding: 5px
}

.search_wrapper .icon.search {
    width: 16px;
    height: 16px;
    background: url("./icons/search.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 7px 21px 0 5px;
}

.search_wrapper .search_input {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 24px;
    border: none;
    background: none;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.select_ {
    width: 178px;
    height: 50px;
    /* left: 365px; */
    /* top: 110px; */
    background: #F2F2F7;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* padding: 0; */
    /* margin: 0; */
    /* identical to box height, or 171% */
    color: #51545C;
}

.selected_options {
    display: flex;
}

.selected_option {
    width: 127px;
    height: 37px;
    left: 517px;
    top: 652px;
    background: #DBDBDC;
    border-radius: 4px;
    padding: 7px;
    margin: 0 10px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected_option .close {
    background: url("./icons/close_alt.svg");
    width: 10.67px;
    height: 10.67px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* table */

.table table {
    width: 100%;
}

.table thead {
    /* border-top: 1px solid var(--glow); */
    border-bottom: 1px solid var(--dark-gray4)
}

.table thead td,
.table thead th {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 171% */
    padding: 5px 12px;
    color: var(--light-gray3);
    text-align: left;
}

.table tbody tr td {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 171% */
    padding: 12px;
    color: var(--light-gray3);
    border-bottom: 1px solid var(--dark-gray3);
}

.table tbody tr:nth-child(even) {
    background: var(--dark-gray2);
}


/* Authentication */

.auth_bg {
    width: 100%;
    height: 100vh;
    background: url("./images/bg_auth.png");
    background-size: cover;
    padding-top: calc(50vh - 284px);
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
}

.auth_form {
    width: 627px;
    height: auto;
    background: var(--dark-gray2);
    -webkit-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.21);
    margin: auto;
    font-family: 'DM Sans', sans-serif;
    border-radius: 10px;
}

.auth_form_container {
    max-width: 406px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 45px 0;
}

.auth_form_container .btn_ {
    width: 100%;
    margin-top: 15px !important;
}

.auth_form_container .logo {
    background: url("./icons//izifin.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 60px;
    height: 60px;
    margin: 0 auto 25px;
}

.auth_form_container .auth_title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    text-align: center;
    color: #353535;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
}

.auth_form_container .label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    font-family: 'DM Sans', sans-serif;
    color: var(--light-gray3);
    margin-top: 0px;
}

.auth_form_container .input_box {
    width: 100%;
    min-height: 54px;
    background: var(--light-gray1);
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    font-family: 'DM Sans';
}

.auth_form_container input {
    line-height: 40px;
    width: calc(100% - 45px);
    padding: 0 20px 0 0;
    text-decoration: none;
    border: none;
    margin: 6px;
    background: none;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border: 0;
    box-shadow: none;
    /* background: #ccc; */
}


.auth_form_container textarea {
    width: 100%;
    border: none;
    background: var(--light-gray1);
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    /* background: #ccc; */
}

.support_message {
    height: 100px;
}

.auth_form_container select {
    line-height: 40px;
    width: 100%;
    padding: 0 20px 0 0;
    text-decoration: none;
    border: none;
    margin: 6px;
    background: none;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    
}

.auth_form_container .more-info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    font-family: 'DM Sans', sans-serif;
    color: #C76400;
    margin-top: 7px;
}

.auth_form_container .togglepassword {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #15AE73;
    line-height: 40px;
    width: 45px;
}

.auth_form_container .fieldset {
    margin-bottom: 20px;
}

.auth_form_container .fieldset label {
    margin-bottom: 20px;
    display: flex;
}


.auth_form_container .fieldset label .label_icon {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    background: #004751;
}

.auth_form_container .fieldset label .label_icon.important {
    height: 10px;
    width: 10px;
    background: url('../assets/icons/asterisks.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.auth_form_container .fieldset label .label_icon.check {
    background: url('../assets/icons/check.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}


/* Modals */

.divider_box {
    height: 30px;
    position: relative;
    margin: 10px 0 23px 0;

}

.divider_box .text {
    font-size: 14px;
    background: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    z-index: 2;
    position: absolute;
    left: 0;
    padding: 4px 10px 4px 0;
    font-family: 'DM Sans';
}

.divider_box .line {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    height: 1px;
    background: #000000;
    z-index: 1;
    opacity: 0.2;

}

.modal_box {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(75, 84, 90, 0.42);
    z-index: 999;
    overflow: auto;
    padding: 20px
}

.modal_inner {
    max-width: 584px;
    /* height: 614px; */
    position: absolute;
    /* left: calc(50% - 292px); */
    /* top: 139px; */
    max-height: 90vh;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px 5px rgba(69, 104, 209, 0.1);
    border-radius: 8px;
}

.modal_close {
    width: 10.67px;
    height: 10.67px;
    position: absolute;
    right: 22.66px;
    top: 22.66px;
    background: url("./icons/cancel.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.add_staff_modal {
    padding: 3rem 4rem;
}

.add_staff_modal .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    text-align: center;
}

.status-success,
.status-paid {
    color: #00CD74!important;
}

.status-pending {
    color: #FF9500!important;
}

.status-failed {
    color: #FF0000!important;
}

.pagination-active {
    color: #C76400 !important;
}

.dropdown-group:hover .dropdown-group-hover\:block {
    display: block;
}

.dropdown-group-hover\:block {
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


/* cashier portal */

.cursor-pointer {
    cursor: pointer;
}

.purchases_wrapper .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--white);
}

.purchases_wrapper .sub_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
}

.purchases_wrapper .proceed_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    height: 50px;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-transform: uppercase;
    padding: 0px 2rem;
    text-align: center;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
    background: #029656;
}

.purchases_wrapper .proceed_btn.inactive {
    background: #C4C4C4;
}

.purchases_wrapper .checkout_title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.purchases_wrapper .checkout_value {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.purchase_tab_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2.5rem;
    /* justify-content: space-between; */
}

.purchase_tab_wrapper.underline {
    border-bottom: 1px solid var(--glow);
}

.purchase_tab_wrapper .purchase_tab-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #86889C;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
}

.purchase_tab_wrapper .purchase_tab-title.active {
    color: var(--white);
    border-bottom: 2px solid #000000;
}

.purchase_item_title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #51545C;
}

.purchase_item_delete {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #FF0000;
    cursor: pointer;
}

.purchase_item_quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
}

.purchase_item_quantity .increase,
.purchase_item_quantity .decrease {
    background: #F4F6FA;
    box-sizing: border-box;
    width: 36px;
    height: 26px;
    text-align: center;
    cursor: pointer;
}

.purchase_item_quantity .value {
    background: #FFFFFF;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    width: 36px;
    height: 26px;
    text-align: center;
}

.purchase_details_modal .total_amount {
    background: #F2F2F7;
}

.purchase_details_modal .total_title {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
}

.purchase_details_modal .total_value {
    font-weight: 500;
    font-style: Medium;
    font-size: 18px;
    line-height: 22px;
}


/* Challenge Page */

.flex_field {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex_field .selectfield {
    width: 48%;
    margin-top: 15px;
}

.flex_field .selectfield .input_box {
    margin-top: 5px;
    font-family: 'DM Sans';
}

.flex_field .selectfield .text {
    font-size: 12px;
    line-height: 24px;
    font-family: 'DM Sans';
}

.flex_field .input_box {
    width: 100%
}

.combofield,
.radiofield {
    display: flex;
    height: 25px;
    align-items: center;
}

.flex_field .combofield {
    width: 30%;
}

.combofield .combobox {
    width: 20px;
    height: 20px;
    border: solid 1px #000000;
    border-radius: 3px;
    font-family: 'DM Sans';
}

.combofield .combobox.active {
    background: url('./icons/stroke.svg') #C76400;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}

.combofield .text,
.radiofield .text {
    margin-left: 8px;
    font-size: 14px;
    line-height: 25px;
    font-family: 'DM Sans';
}


.flex_field .radiofield {
    width: 50%;
}

.radiofield .radiobox {
    width: 18.33px;
    height: 18.33px;
    border: solid 1px #000000;
    border-radius: 3px;
    border-radius: 50%;
    cursor: pointer;
}

.radiofield .radiobox.active {
    width: 18.33px;
    height: 18.33px;
    border: inset 6.5px #029656;
    border-radius: 3px;
    border-radius: 50%;
}

.toggle_container {
    width: 30px;
    height: 17px;
    background: #B7B9CC;
    border-radius: 100px;
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.toggle_knob {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    right: unset;
    left: 1px;
    transition-duration: 1s;
}

.toggle_container.active {
    background: #C76400;
}

.toggle_container.active .toggle_knob {
    right: 1px;
    left: unset;
    transition-duration: 1s;
    position: absolute;
}


/* Resource Widget */
.icon.rating_icon {
    width: 15px;
    height: 15px;
    background: url("../assets/icons/star.svg");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 5px 2px 0px 10px;
}

.resource_widget {
    width: 100%;
    height: 81px;
    border-bottom: 1px solid #00000011;
    display: flex;
    padding-top: 20px;
}

.resource_widget .avatar {
    width: 40px;
    height: 40px;
    background: #999;
    border-radius: 50%;
    margin-right: 10px;
}


.resource_widget .avatar.personnel_icon {
    background: url('../assets/icons/personnel_.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.resource_widget .avatar.table_icon {
    background: url('../assets/icons/dining_.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.resource_widget .action {
    padding-top: 8px
}

.resource_widget .details {
    width: calc(100% - 82px);
}

.resource_widget .resource_name {
    display: flex;
}

.resource_widget .resource_name .name {
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
    font-weight: 600;
}

.resource_widget .resource_name .rating {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5A607F;
    margin: 5px 0;
}

.resource_widget .details .resource_more {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5A607F;
}

/* Challenges  */

.challenge_box {
    max-width: 780px;
    width: 100%;
    height: auto;
    padding: 0 32px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
    justify-content: space-between;
}

.challenge_box_tr {
    min-height: 100px;
    display: flex;
    padding: 27px 0;
}

.challenge_box_tr:not(:last-child) {
    border-bottom: 1px solid #00000020;
}

.challenge_box_tr .title_box {
    width: calc(100% - 300px);
}

.challenge_box_tr .title_box .title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #353535;
}

.challenge_box_tr .title_box .challengeType {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height */
  color: #353535;
}

.challenge_box_tr .title_box .subtitle {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #353535;
}

.challenge_box_td {
    min-width: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 9px;
}

.challenge_box_td .item_text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding-right: 10px;
    text-align: right;
    width: 150px;
    /*     background:#ccc; */
    /* background: #ccc; */
}

.challenge_box_td .item_action {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    color: #029656;
    cursor: pointer;
}

.challenges_title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    color: var(--white);
}

.multi-preview {
    gap: 0.6875rem;
}

.preview-image-container {
    position: relative;
}

/* Make the image responsive */
.preview-image-container .img {
    height: 38px;
    width: 52px;
}

/* Style the button and place it in the middle of the container/image */
.preview-image-container .remove {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
}

.preview-image-container .remove:hover {
    background-color: black;
}

/* timelines styles */

.calendar_container .rc_root {
    margin: 24px 0 !important;
}

.timeline_container {
    display: flex;
    width: 310px;
}

.calendar_container h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.timeline_container .time_labels {
    width: 70px;
    margin-right: 15px;
    padding-top: 22px;
}

.timeline_container .time_label {
    line-height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 171% */
    color: var(--white);
    text-align: left;
}

.timeline_container .timelines {
    width: calc(100% - 85px);
    position: relative;
}

.timeline_container .lines {
    opacity: 0.1;
    border: 1px solid #000000;
    margin-top: 41.6px;
    width: 100%;
}

.timelines .timeline_event {
    position: absolute;
    width: 160px;
    height: 42px;
    top: 44px;
    left: calc(50% - 80px);
    background: #FFE1C4;
}

.timelines .timeline_event p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
}

/* On screens that are 600px or less, set the background color to olive */

@media screen and (max-width: 1300px) {
    .piechart {
        margin: auto;
    }

    .piechart_wrapper {
        display: block
    }

    .piechart_details {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .sidebar_ .close_menu_bar {
        width: 30px;
        height: 30px;
        background: url("./icons/close.svg");
        /* background: 000; */
        background-size: cover;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .sidebar_ {
        position: absolute !important;
        z-index: 1111;
        left: 0;
        top: 0;
        display: none;
    }

    .topbar .page-anchor {
        display: block !important;
        position: absolute;
        left: 10px;
    }

    .topbar .title {
        display: none !important;
    }

    .topbar .search_wrapper {
        display: none !important;
    }

    .download_button {
        display: none;
    }

    .show_sidebar {
        display: block;
    }

    .mainbar {
        width: 100%;
    }

    .summary_box {
        width: calc(50% - 20px);
        /* margin-right: 0;   */
    }

    .table-title {
        display: none;
    }
}


@media screen and (min-width: 650px) {
  .modal_inner {
    width: 584px;
  }
}

@media screen and (max-width: 650px) {
    .topbar {
        width: 100% !important;
        padding: 0.813rem 2.063rem;
    }

    .summary_box {
        width: 100%;
        margin-right: 0;
    }

    .download_button {
        display: none;
    }

    .btn_ {
        width: 100%;
    }

    .topbar .right-box {
        /* width: 250px */
    }

    .mainbar-container {
        padding: 0.813rem 2.063rem;
        width: 100%;
        margin: unset;
    }

    .full-mainbar-container {
        margin: 0.813rem 2.063rem;
    }

    .page-filter {
        width: 100%;
        position: unset;
        height: auto !important;
        margin-bottom: 20px;
        /* justify-content: center !important; */
        z-index: 1111;
    }
}

.accountName {
    color: #C76400;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}


.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

/* Setup image file upload */
#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: var(--light-gray1);
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.profile-image {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  border-radius: 50%; /* Makes the image round */
  overflow: hidden; /* Clips the image to the border radius */
  border: 2px solid #fff; /* Optional: adds a border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow */
  background: var(--white);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
}